import React from 'react';
import Router from './Router';
import Sampling_report from './screens/Sampling_report';
import IQACalendar from './screens/Calendar';

class App extends React.Component {
  render() {
    return (
      <Router />
    );
  }
}

export default App;
