import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import LoginScreen from './screens/Login_Screen';
import Learner_Group from './screens/Learner_Group';
import Logout from './screens/Logout';
import IQACalendar from './screens/Calendar';
import PageNotFound from './screens/components/PageNotFound';
import ForgotScreen from './screens/ForgotPassword';
import Sampling_report from './screens/Sampling_report';
import Messages from './screens/Messages';
import Profile from './screens/Profile';


export default class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={`/`} component={LoginScreen} exact />
                    <Route path={`/dashboard`} component={Learner_Group} />
                    <Route path={`/Sampling_report`} component={Sampling_report} />
                    <Route path={`/Logout`} component={Logout} />
                    <Route path={`/IQACalendar`} component={IQACalendar} />
                    <Route path={`/messages`} component={Messages} />
                    <Route path={`/profile`}component={Profile} />
                    <Route path={`/forgotPassword`} component={ForgotScreen} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </BrowserRouter>
        )
    }
}