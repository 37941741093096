import React from 'react';
// import API from './Components/Global_API'


class Logout extends React.Component {
    constructor(props) {
        super(props);
        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
        var hours = new Date().getHours();
        var min = new Date().getMinutes();
        var sec = new Date().getSeconds();
        this.state = {
            user: '',
            current_date: year + '-' + month + '-' + date + ' ' + hours + ':' + min + ':' + sec,
        }
        if (localStorage.getItem("IqaloginUser")) {
            this.state.user = JSON.parse(localStorage.getItem("IqaloginUser"));
        }
    }

    componentWillMount(){
        let loginUser = localStorage.getItem("IqaloginUser")
        if(loginUser==null){
           window.location.href='/';
        }
    }
    
    componentDidMount() {

        this.Logout();

    }
    Logout = () => {
        localStorage.removeItem("IqaloginUser");
        this.props.history.push("/");
       



    }
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }} >
                <img src={require('../assets/Loader.gif')} alt="loader" />
            </div>
        );
    }

}

export default Logout;
